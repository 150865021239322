export const addSampleToThdCart = (itemId, quantity = '1', storeNumber = '121') => {
  if (!itemId) return Promise.resolve('Error: No itemId provided');

  if (typeof window !== 'undefined') {
    const {
      channel = 'desktop',
      deliveryZip = '77573'
    } = window.__EXPERIENCE_CONTEXT__;

    window.LIFE_CYCLE_EVENT_BUS.trigger('cart.add-to-cart', {
      cartReqParams: [
        {
          itemId: String(itemId),
          quantity: String(quantity),
          fulfillmentLocation: String(deliveryZip),
          fulfillmentMethod: 'ShipToHome',
        },
      ],
      cartOptions: {
        host: window.location.origin || 'https://www.homedepot.com',
        channel,
        skipMisship: true
      },
    });

    return Promise.resolve('Success');
  }

  return Promise.resolve('Error: Failed to Add To Cart');
};

export default {
  addSampleToThdCart
};
