/* globals injectedConfiguratorSettings */
import React from 'react';
import PropTypes from 'prop-types';
//import { BarLoader } from 'react-spinners';
import {
  addSampleToThdCart,
  sendSampleAddToCartAnalytics,
} from '../../../services';

class SampleBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleAddedToCart: false,
      isBusy: false,
    };
    this.onAddToCart = this.onAddToCart.bind(this);
  }

  onAddToCart = () => {
    const { storeId, sample, closeModal } = this.props;


    this.setState({ isBusy: true });

    return addSampleToThdCart(sample.omsId, 1, storeId)
      .then((response) => {
        // console.log(`addSampleToThdCart response: ${JSON.stringify(response)}`);
        if (
          !(
            response.data
            && (response.data.CartModel && response.data.CartModel.errorModel)
          )
        ) {
          this.setState({
            sampleAddedToCart: true,
            isBusy: false,
          });

          closeModal();
        } else {
          this.setState({
            isBusy: false,
          });
          console.log(`Error addSampleToThdCart ${JSON.stringify(response)}`);
          closeModal();
        }
      })
      .catch((error) => {
        this.setState({ isBusy: false });
        console.log(`Error addSampleToThdCart ${JSON.stringify(error)}`);
        throw error;
      });
  };

  renderAddSampleToCartButton = () => (
    <div
      role="button"
      id="addToCartBtn"
      onClick={() => this.onAddToCart()}
      tabIndex="0"
      style={{
        flex: '0 0 auto',
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#f96302',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '#f96302',
        height: '30px',
        width: '100%',
        fontSize: '16px',
        marginTop: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
    >
      Add to Cart
    </div>
  );

  renderNoSample = () => (
    <div
      style={{
        flex: '0 0 auto',
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#4a4a4a',
        height: '30px',
        width: '100%',
        fontSize: '16px',
        marginTop: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
    >
      No Sample
    </div>
  );

  renderSampleAddedToCart = () => (
    <div
      style={{
        flex: '0 0 auto',
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'green',
        height: '30px',
        width: '100%',
        fontSize: '16px',
        marginTop: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
    >
      Sample Added
    </div>
  );

  renderLoading = () => (
    <div
      style={{
        flex: '0 0 auto',
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#f96302',
        height: '30px',
        width: '100%',
        fontSize: '16px',
        marginTop: '10px',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
    >
      {/* <BarLoader color="#f96302" loading={this.state.isBusy} /> */}
    </div>
  );

  renderSamplePrice = (price, imageUrl) => {
    const [dollars, cents] = price.toFixed(2).split('.');
    return (
      <div
        style={{
          flexDirection: 'column',
          flex: '0 0 auto',
          display: 'flex',
          height: '140px',
          width: '140px',
          marginBottom: '10px',
          backgroundImage: imageUrl,
        }}
      >
        <div
          style={{
            flex: '0 0 auto',
            display: 'flex',
            marginTop: '10px',
            backgroundColor: 'rgba(0,0,0,.5)',
            alignSelf: 'flex-end',
            padding: '3px',
          }}
        >
          <div
            style={{
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '25px',
            }}
          >
            <span
              style={{ fontSize: '12px', position: 'relative', top: '-6px' }}
            >
              $
            </span>
            <span>{dollars}</span>
            {cents !== '00' && (
              <span
                style={{
                  fontSize: '14px',
                  position: 'relative',
                  top: '-6px',
                }}
              >
                {cents}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isAvailable } = this.props.sample;
    const imageUrl = `url("${this.props.eleanorBaseUrl}${this.props.sample.imageUrl}")`;
    return (
      <div
        style={{
          flexDirection: 'column',
          flex: '0 0 auto',
          display: 'flex',
          margin: '10px',
          marginTop: '20px',
        }}
      >
        {this.renderSamplePrice(this.props.sample.price, imageUrl)}
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {this.props.sample.name}
        </span>

        {
          <div
            style={{
              flexDirection: 'row',
              flex: '0 0 auto',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {!this.state.sampleAddedToCart
              && !this.state.isBusy
              && isAvailable
              && this.renderAddSampleToCartButton()}
            {!this.state.sampleAddedToCart
              && this.state.isBusy
              && this.renderLoading()}
            {this.state.sampleAddedToCart && this.renderSampleAddedToCart()}
            {!isAvailable && this.renderNoSample()}
          </div>
        }
      </div>
    );
  }
}

SampleBox.propTypes = {
  storeId: PropTypes.string.isRequired,
  eleanorBaseUrl: PropTypes.string.isRequired,
  sample: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default SampleBox;
