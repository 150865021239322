/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
//import { BarLoader } from 'react-spinners';
import SampleBox from './SampleBox';
import { getSamples } from '../../../services';
import './sampleLayout.scss';

class SampleLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      samples: [],
      isBusy: false,
    };
    this.onCheckout = this.onCheckout.bind(this);

  }

  componentDidMount() {
    this.loadData();
  }

  onCheckout = () =>
    this.setState({ isBusy: true }, () =>
      window.location.assign(this.props.configs.externalCartUrl),
    );

  loadData() {
    const { storeId, productId } = this.props;

    this.setState({
      isBusy: true,
    });

    getSamples(this.props.configs.decksApiUrl,productId, storeId)
      .then(response => {
        // console.log('Samples response.data: ', JSON.stringify(response.data));
        this.setState({
          samples: response.data,
          isBusy: false,
        });
      })
      .catch(error => {
        console.log('Error Loading Samples: ', JSON.stringify(error));
        this.setState({
          isBusy: false,
        });
      });
  }



  render() {
    return (
      <div className='sampleLayout'>
        <div className='sampleContent'>
          {this.state.isBusy && (
            <div className='loaderContainer'>
              {/* <BarLoader color="#f96302" loading={this.state.isBusy} /> */}
            </div>
          )}
          {!this.state.isBusy && this.state.samples.length > 0 && (
            <div className='sampleList'>
              <div className='samplesContainer'>
                {this.state.samples.map((sample, i) => (
                  <SampleBox
                    key={i}
                    sample={sample}
                    storeId={this.props.storeId}
                    productId={this.props.productId}
                    eleanorBaseUrl={this.props.configs.eleanorBaseUrl}
                    closeModal={this.props.closeModal}
                  />
                ))}
              </div>
            </div>
          )}
          {!this.state.isBusy && this.state.samples.length === 0 && (
            <div className='unavailable'>
              <span>
                <b>Samples are currently unavailable.</b>
                {this.props.configs.showCustomerSupportInfo && (
                  <>
                    Please try back later or call{' '}
                    <b>
                      {this.props.configs.customerSupportPhoneNum}.
                    </b>
                  </>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SampleLayout.propTypes = {
  storeId: PropTypes.string.isRequired,
  productId: PropTypes.number.isRequired,
  eleanorBaseUrl: PropTypes.string.isRequired,
  thdCartApiUrl: PropTypes.string.isRequired,
  thdCartUrl: PropTypes.string,
  closeModal: PropTypes.func.isRequired
};

export { SampleLayout };
