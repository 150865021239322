/* eslint no-use-before-define: ["error", { "variables": false }] */
import Api from './axios';

export const getSamples = (
  decksApiUrl,
  productId,
  storeId,
) => {
  const api = Api();
  Object.assign(api.defaults, { withCredentials: true });
  const url = `${decksApiUrl}/Samples/${productId}/${storeId}`;
  // console.log('getSamples Uirl:', url);
  return api.get(url);
};

export default {
  getSamples,
};
